<script>

import appConfig from "@/app.config";
import { required, } from "vuelidate/lib/validators";
import { useSystemApi } from "@/apis/system";



/**
 * Advanced table component
 */
export default {
  page: {
    title: '仓库信息',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {


  },

  validations() {
    return {
      storage_code: { required },
      storage_name: { required }
    }
  },


  data() {
    return {


      totalRows: 1,
      todoTotalRows: 1,
      currentPage: 1,
      todocurrentPage: 1,
      perPage: 10,


      fields: [


        {
          key: "code",
          label: '仓库编码',
          sortable: true,
        },

        {
          key: "name",
          label: '仓库名称',
          sortable: true,
        },


        {
          key: "Pos",
          label: this.$t('system.produce.table_fields.action'),
          sortable: false,
        },
      ],

      listData: [],
      storage_name    : '',
      storage_code    : '',
      storage_remarks : '',
      update_flag: false,
      update_id: '',
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.listData.length;
    },


  },
  mounted() {

    this.queryStorage();

  },
  methods: {


    queryStorage() {
      useSystemApi().list_system_conf('STORAGE').then(res => {
        if (res.err == 0) {
          this.listData = []
          res.data.map(e => {
            this.listData.push({
              code    : e.key_field,
              name    : e.value_field,
              remarks : e.remarks,
              id      : e.id
            })
          })
        }
      })
    },


    newStorage() {
      this.update_flag = false;
      this.$bvModal.show('storage_dialog')
    },


    editStorage(item) {
      this.update_flag     = true;
      this.storage_code    = item.code
      this.storage_name    = item.name
      this.storage_remarks = item.remarks
      this.update_id       = item.id

      this.$bvModal.show('storage_dialog')
    },

    deleteStorage(item) {
      useSystemApi().delete_system_conf(item.id).then(res => {
        if (res.err == 0) {
          this.queryStorage()
        } else {
          this.$alertify.error("删除失败 " + res.err + ":" + res.msg);
        }
      })
    },

    submitStorage() {
      this.$v.$touch()

      if (this.$v.$invalid == false) {

        let data = {
          category: 'STORAGE',
          key     : this.storage_code,
          value   : this.storage_name,
          remarks : this.storage_remarks
        }

        if (this.update_flag == true) {
          data.id = this.update_id
          useSystemApi().modify_system_conf(data).then(res => {
            if (res.err == 0) {
              this.$bvModal.hide('storage_dialog')
              this.queryStorage()
            } else {
              this.$alertify.error("编辑失败 " + res.err + ":" + res.msg);
            }
          })

        } else {
          useSystemApi().add_system_conf(data).then(res => {
            if (res.err == 0) {
              this.$bvModal.hide('storage_dialog')
              this.queryStorage()
            } else {
              this.$alertify.error("失败 " + res.err + ":" + res.msg);
            }
          })

        }


      }
    },



  },
};
</script>

<template>


  <div class="row">

    <b-modal id="storage_dialog" title="仓库配置" size="lg" centered hide-footer>
      <div class="row mb-3">

        <div class="col-md-4">
          <label class="form-label">仓库编码</label>
          <input type="text" class="form-control" v-model="storage_code" maxlength="20"
            :class="{ 'is-invalid': $v.storage_code.$error }">
          <div v-if="$v.storage_code.$error" class="invalid-feedback">
            <span v-if="!$v.storage_code.required">请输入型号编码</span>
          </div>
        </div>
        <div class="col-md-4">
          <label class="form-label">仓库名称</label>
          <input type="text" class="form-control" v-model="storage_name" maxlength="30"
            :class="{ 'is-invalid': $v.storage_name.$error }" />
          <div v-if="$v.storage_name.$error" class="invalid-feedback">
            <span v-if="!$v.storage_name.required">请输入型号名称</span>
          </div>
        </div>

        <div class="col-md-4">
          <label class="form-label">仓库说明</label>
          <input type="text" class="form-control" v-model="storage_remarks" maxlength="150" />
        </div>

      </div>
      <div class="row mb-3 ">

        <div class="col-md-8"></div>
        <div class="col-md-4  gap-2 justify-content-end d-flex align-items-right">
          <b-button variant="secondary" @click="$bvModal.hide('storage_dialog')">取消</b-button>
          <b-button variant="primary" @click="submitStorage">确认</b-button>
        </div>

      </div>
    </b-modal>

    <div class="row">
      <div class="col-sm-6 col-md-3">

      </div>

      <div class="col-sm-12 col-md-3">
        <div class="row">

          <div class="col-md-6">
          </div>

        </div>


      </div>

      <div class="col-sm-6 col-md-3">
      </div>
      <!-- Search -->
      <div class="col-sm-6 col-md-3">
        <div class=" d-flex flex-wrap  justify-content-md-end gap-2">
          <b-button variant="secondary" @click="newStorage">添加仓储</b-button>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <b-table class="datatables" :items="listData" :fields="fields" responsive="sm" :per-page="perPage"
        :current-page="currentPage"  style="min-height: 20dvh;">

        <template #cell(Pos)="data">
          <b-dropdown variant="white" toggle-class="p-0">
            <template v-slot:button-content>
              <i class="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
            </template>
            <li>
              <b-dropdown-item @click="editStorage(data.item)"><i
                  class="mdi mdi-pencil font-size-16 text-success me-1"></i> 编辑
              </b-dropdown-item>
              <b-dropdown-item @click="deleteStorage(data.item)"><i
                  class="mdi mdi-pencil font-size-16 text-success me-1"></i> 删除
              </b-dropdown-item>
            </li>

          </b-dropdown>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>



</template>
