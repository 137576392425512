<script>

import appConfig from "@/app.config";
import { required, } from "vuelidate/lib/validators";
import { useSystemApi } from "@/apis/system";



/**
 * Advanced table component
 */
export default {
  page: {
    title: '型号信息',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {


  },

  validations() {
    return {
      model_code: { required },
      model_name: { required }
    }
  },


  data() {
    return {


      totalRows: 1,
      perPage: 50,
      currentPage : 1,
      filter: null,
      todoFilter: null,
      filterOn: [],
      todofilterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [


        {
          key: "model",
          label: this.$t('system.produce.table_fields.model'),
          sortable: true,
        },

        {
          key: "tag",
          label: this.$t('system.produce.table_fields.tag'),
          sortable: true,
        },


        {
          key: "Pos",
          label: this.$t('system.produce.table_fields.action'),
          sortable: false,
        },
      ],

      listData: [],
      model_name: '',
      model_code: '',
      update_flag: false,
      update_id: '',
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },


  },
  mounted() {

    this.query_model();

  },
  methods: {


    query_model() {
      this.listData = []
      useSystemApi().list_system_conf('MODEL', {page_no : this.currentPage , page_size : this.perPage}).then(res => {
        if (res.err == 0) {
          
          res.data.map(e => {
            this.listData.push({
              model: e.key_field,
              tag: e.value_field,
              id: e.id
            })
          })
          
          this.totalRows = res.page.total_count
        }
      })
    },


    newModel() {
      this.update_flag = false;
      this.$bvModal.show('model_dialog')
    },


    editModel(item) {
      this.update_flag = true;
      this.model_code = item.model
      this.model_name = item.tag
      this.update_id = item.id

      this.$bvModal.show('model_dialog')
    },

    deleteModel(item) {
      useSystemApi().delete_system_conf(item.id).then(res => {
        if (res.err == 0) {
          this.query_model()
        } else {
          this.$alertify.error("删除失败 " + res.err + ":" + res.msg);
        }
      })
    },

    submitModel() {
      this.$v.$touch()

      if (this.$v.$invalid == false) {

        let data = {
          category: 'MODEL',
          key: this.model_code,
          value: this.model_name
        }

        if (this.update_flag == true) {
          data.id = this.update_id
          useSystemApi().modify_system_conf(data).then(res => {
            if (res.err == 0) {
              this.$bvModal.hide('model_dialog')
              this.query_model()
            } else {
              this.$alertify.error("编辑失败 " + res.err + ":" + res.msg);
            }
          })

        } else {
          useSystemApi().add_system_conf(data).then(res => {
            if (res.err == 0) {
              this.$bvModal.hide('model_dialog')
              this.query_model()
            } else {
              this.$alertify.error("失败 " + res.err + ":" + res.msg);
            }
          })

        }


      }
    },

    pageChange(p) {
      this.currentPage = p
      this.query_model()
    },



  },
};
</script>

<template>


  <div class="row">

    <b-modal id="model_dialog" title="型号配置" size="lg" centered hide-footer>
      <div class="row mb-3">

        <div class="col-md-4">
          <label class="form-label">型号编码</label>
          <input type="text" class="form-control" v-model="model_code" maxlength="20"
            :class="{ 'is-invalid': $v.model_code.$error }">
          <div v-if="$v.model_code.$error" class="invalid-feedback">
            <span v-if="!$v.model_code.required">请输入型号编码</span>
          </div>
        </div>
        <div class="col-md-4">
          <label class="form-label">型号名称</label>
          <input type="text" class="form-control" v-model="model_name" maxlength="30"
            :class="{ 'is-invalid': $v.model_name.$error }" />
          <div v-if="$v.model_name.$error" class="invalid-feedback">
            <span v-if="!$v.model_name.required">请输入型号名称</span>
          </div>
        </div>

        <div class="col-md-4">
          <label class="form-label">型号说明</label>
          <input type="text" class="form-control" v-model="model_name" maxlength="150" />
        </div>

      </div>
      <div class="row mb-3 ">

        <div class="col-md-8"></div>
        <div class="col-md-4  gap-2 justify-content-end d-flex align-items-right">
          <b-button variant="secondary" @click="$bvModal.hide('dept_dialog')">取消</b-button>
          <b-button variant="primary" @click="submitModel">确认</b-button>
        </div>

      </div>
    </b-modal>

    <div class="row">
      <div class="col-sm-6 col-md-3">

      </div>

      <div class="col-sm-12 col-md-3">
        <div class="row">

          <div class="col-md-6">
          </div>

        </div>


      </div>

      <div class="col-sm-6 col-md-3">
      </div>
      <!-- Search -->
      <div class="col-sm-6 col-md-3">
        <div class=" d-flex flex-wrap  justify-content-md-end gap-2">
          <b-button variant="secondary" @click="newModel">{{ $t('system.produce.btn.add_model')
            }}</b-button>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <b-table class="datatables" :items="listData" :fields="fields" responsive="sm" :per-page="perPage" striped hover   style="min-height: 20dvh;">

        <template #cell(model)="data">
          {{ data.item.model }}
        </template>

        <template #cell(Pos)="data">
          <b-dropdown variant="white" toggle-class="p-0">
            <template v-slot:button-content>
              <i class="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
            </template>
            <li>
              <b-dropdown-item @click="editModel(data.item)"><i
                  class="mdi mdi-pencil font-size-16 text-success me-1"></i> 编辑
              </b-dropdown-item>
              <b-dropdown-item @click="deleteModel(data.item)"><i
                  class="mdi mdi-pencil font-size-16 text-success me-1"></i> 删除
              </b-dropdown-item>
            </li>

          </b-dropdown>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"  @change="pageChange" ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>



</template>
